/* eslint-disable max-len */
import {
  RISK_SECTION_HIGH,
  RISK_SECTION_LOW,
  RISK_SECTION_MEDIUM,
  RISK_SECTION_VERY_HIGH,
  RISK_SECTION_VERY_LOW,
} from '@consts/risk';
import {
  formatBMIQuestion,
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL, QUESTION_TYPE_GENDER } from '@consts/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_VERY_LOW, 'common.very_low', 'risk.very_low', 0, 0),
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 1, 2),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 2, 4.5),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 4.5, 6.5),
  formatRisk(RISK_SECTION_VERY_HIGH, 'common.very_high', 'risk.very_high', 6.5, 99999),
];

export const resultSettings = [
  formatResult(
    'Det verkar inte som att du löper risk att utveckla artros i knät. Om behovet uppstår kan du kontakta en fysioterapeut via Joint Academy.',
    '',
    0,
    0,
  ),
  formatResult(
    'Artros är en långvarig sjukdom vars symtom som kan öka gradvis. Med bedömning och behandling i god tid kan utvecklingen bromsas. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Enligt dina testsvar verkar du ha lättare besvär av din knäsmärta och och din risk för att ha eller utveckla artros bedöms som låg. Däremot är det alltid bra att genomföra en utredning i god tid om man misstänker artros, eller har artros i släkten. Artros är en livsstilssjukdom där det går att både bromsa sjukdomsförloppet och proaktivt minska risken för ökade besvär.<br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för knäartros. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid, och rekommenderas av Socialstyrelsen och internationella riktlinjer.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    1,
    2,
  ),
  formatResult(
    'Artros är en långvarig sjukdom men det går att minska besvären med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Baserat på dina testsvar verkar du besväras av din knäsmärta i vardagen och risken för att utveckla artros bedöms som måttlig. Det kan vara bra för dig att genomföra en bedömning av legitimerad vårdpersonal, och få hjälp för att undvika att besvären förvärras. <br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för artros i knät. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    2,
    4.5,
  ),
  formatResult(
    'Artros är en långvarig sjukdom men det går att minska besvären med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Baserat på dina testsvar verkar du ha mycket besvär av din knäsmärta och risken för artros bedöms som hög. Det kan vara bra för dig att genomföra en bedömning av legitimerad vårdpersonal, och få hjälp för att undvika att besvären förvärras. <br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för artros i knät. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    4.5,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Har du haft smärta i knäet vid rörelse eller belastning, under den senaste månaden?',
    2,
  ),
  formatBMIQuestion('question2'),
  formatQuestion(
    'question3',
    'Upplever du stelhet på morgonen som går över efter ca 30-60 min?',
    [
      formatOption('common.yes', 1, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Nej, ingen stelhet', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('Nej, långvarig stelhet', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
    ],
  ),
  formatQuestion(
    'question4',
    'Vad är ditt kön?',
    [
      formatOption('Kvinna', 1, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'woman'),
      formatOption('Man', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'man'),
      formatOption('Annat', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'other'),
    ],
    QUESTION_TYPE_GENDER,
  ),
  formatYesNoQuestion(
    'question5',
    'Har du, eller har du haft, ett arbete som medför stående, gående eller roterande uppgifter?',
  ),
  formatYesNoQuestion(
    'question6',
    'Har du vid något tillfälle skadat knäleden så allvarligt att du uppsökt sjukvård?',
    2,
  ),
];

export const references = [
  'American College of Rheumatology. Criteria [Internet]. Atlanta: American College of Rheumatology [citerad 16 juni 2021] Hämtad från: <a href="https://www.rheumatology.org/Practice-Quality/Clinical-Support/Criteria">https://www.rheumatology.org/Practice-Quality/Clinical-Support/Criteria</a>',
  'Altman R, Alarcón G, Appelrouth D, et al. The American College of Rheumatology criteria for the classification and reporting of osteoarthritis of the hip. Arthritis Rheum. 1991;34(5):505-514. doi:10.1002/art.1780340502',
  'Saberi Hosnijeh F, Kavousi M, Boer CG, et al. Development of a prediction model for future risk of radiographic hip osteoarthritis. Osteoarthritis Cartilage. 2018;26(4):540-546. doi:10.1016/j.joca.2018.01.015',
  'Altman R, Asch E, Bloch D, et al. Development of criteria for the classification and reporting of osteoarthritis. Classification of osteoarthritis of the knee. Diagnostic and Therapeutic Criteria Committee of the American Rheumatism Association. Arthritis Rheum. 1986;29(8):1039-1049. doi:10.1002/art.1780290816',
  'Zheng H, Chen C. Body mass index and risk of knee osteoarthritis: systematic review and meta-analysis of prospective studies. BMJ Open. 2015 Dec 11;5(12):e007568. doi: 10.1136/bmjopen-2014-007568. PMID: 26656979; PMCID: PMC4679914.',
  'Felson et al, Arthr. and Rheum, 1997Felson DT, Zhang Y, Hannan MT, et al. Risk factors for incident radiographic knee osteoarthritis in the elderly: the Framingham Study. Arthritis Rheum. 1997;40(4):728-733. doi:10.1002/art.1780400420',
  'Blagojevic M, Jinks C, Jeffery A, Jordan KP. Risk factors for onset of osteoarthritis of the knee in older adults: a systematic review and meta-analysis. Osteoarthritis Cartilage. 2010;18(1):24-33. doi:10.1016/j.joca.2009.08.010',
];
